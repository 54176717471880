.company__card {
  height: auto;
  width: 30rem;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.2rem 5rem rgba($color: $color-black, $alpha: 0.1);

  &:hover {
    box-shadow: 2rem 2rem 5rem rgba($color: $color-black, $alpha: 0.2);
    transition: 0.5s all;
  }

  @include respond(small-phone) {
    width: 35rem;
    height: 40rem;
  }

  &__icon {
    margin: 0;
    &__data {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0.5rem;
    }
  }

  &__content {
    &__title {
      padding: 2rem 0;
    }

    &__subtitle {
      padding: 2rem 0;
    }
  }
}
