body {
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    /* width: 100%; arpit */
    max-width: 2900px;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
}

.faq-grid {
    display: grid;
    grid-template-columns: 30px auto auto auto auto 30px;
    gap: 20px;
    padding: 0, 30px;
    margin: 0 auto;
    grid-template-areas: ". content content content content .";
}

.faq-hero-header {
    background-image:  url('../../assets/shakaijinfaq-landing.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100%
}

.faq-header-content {
    grid-area: content;
    max-width: 1600px;
    width: 100%;
    margin: 2.5em auto 0 auto;
}

.faq-sha-landing-hero-content {
    margin: 21em auto 50em auto;
    text-align: center;
}

.faq-sha-title {
    font-size: 8em;
    line-height: 1.6em;
    color: #FEFEFE;
}

.faq-sha-join-program-btn {
    font-size: 3.5em;
    font-weight: bold;
    background: #00BAFF;
    border-radius: 5px;

    margin-top: 1.35em;
    padding: 0.5em 3em;

    color: #ffffff;
}

.sha-faq-body {
    margin: 0 4em;
    padding: 0 4em;
}

.about-section {
    text-align: center;
    /* margin: 5em 10em; arpit */
}

.about-title {
    font-size: 5em;
    font-weight: bold;
    color: #535353;
}

.about-text {
    margin-top: 1em;
    font-size: 2em;
    font-weight: normal;
    line-height: 2.2em;
}


.faq-table-contents {
    font-size: 2em;
    margin-bottom: 3.5em;
    text-decoration: underline;

}

.FAQs-section {
    text-align: center;
    /* margin: 5.5em auto; arpit */
    /* padding: 0 50px; arpit */
}

.FAQs-header {
    font-size: 6em;
    font-weight: bold;
    color: #535353;
    margin-bottom: 0.3em;
}

.shakaijin_faq_dropdowns {
    margin-bottom: 4em;
}

.dropdown-section-title { 
    font-size: 3em;
    font-weight: bold;
    color: #535353;
    text-decoration: underline;
    margin-top: 3em;
}

a {
    color: #535353;
}