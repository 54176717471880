.landingpage {
  &__header {
    @include respond(tab-port) {
      padding: 5rem 0 15rem 0;
    }

    @include respond(small-phone) {
      padding: 5rem 0 5rem 0;
    }
    &__section {
      @include respond(tab-port) {
        margin-top: 10rem;
      }
      &__content {
        height: 100vh;
        display: grid;
        gap: 0.5rem;
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        @include respond(small-phone) {
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        }

        &__text {
          margin: 0 10rem;
          &__heading {
            margin: 0 0 1rem 0;
            text-align: left;
            display: block;
            font-size: 5rem;
            line-height: 6rem;

            @include respond(phone) {
              font-size: 4rem;
              line-height: 5rem;
              margin-top: -5rem;
            }

            span {
              display: block;
              font-size: 5rem;
              color: $color-primary;

              @include respond(phone) {
                font-size: 4rem;
              }
            }
          }

          &__info {
            display: block;
            margin: 0 0 2rem 0;
            font-size: 1.6rem;
            color: $color-black;

            @include respond(tab-land) {
              font-size: 2rem;
            }
          }

          @include respond(tab-port) {
            order: 2;
            margin-top: 0;
          }

          @include respond(tab-land) {
            margin-top: 2rem;
            width: 75%;
          }

          @include respond(small-phone) {
            margin: 2rem;
          }
        }

        &__image {
          margin-top: -5rem;
          &__item {
            margin: 0 auto 0 auto;
            display: block;
            width: 65%;
            height: auto;
          }

          @include respond(tab-port) {
            // margin-top: 0;
            order: 1;
          }
        }
      }
    }
  }
}
