.brand-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.9em;
    width:100%;
}

.shakaijin-logo {

}

.shakaijin-logo-txt {
    margin-left: 1.4em;

    // margin-left: 1.4em;
    margin-top: 0.2em;
    width: 20%;
}

.shakaijin-nav-bar {
    width: 80%;
}

.shakaijin-logo-maintxt {
    font-size: 2.6em;
    letter-spacing: 0.05em;
    line-height: 0.3em;
    color: #00BAFF;
  
}

.shakajin-logo-subtxt {
    font-size: 1.6em;
    letter-spacing: 0.05em;
    line-height: 0.7em;

    color: black;
    padding: 0;
    margin: 0;

}

.logo-new {
    width: 6em;
}

.shakaijin-faq-btn {
    background-color: Transparent;
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 2.1em;

    margin-left: auto;
    margin-right: 2em;

    &:hover,
    &:focus {
        color: #e0e0e0;
        outline: none;
      }
}

.shakaijin-change-lang-btn {
    background: #00BAFF;
    border-radius: 5px;
    font-weight: bold;
    font-size: 2.1em;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/assets/images/Vector 1sh-dropdown-arrow.png');
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 85%;

    width: 7.7em;
    height: 2.4em;
    padding-left: 0.6em;

    outline: none;
    border: none;

    color: #FFFFFF;

    &:focus {
        outline: none;
      }
    &:hover {
        box-shadow: 0rem 1rem 2rem rgba($color: $color-black, $alpha: 0.2);
    }

}