// COLORS
$color-primary: #00baff;
$color-background: #fcfcfc;

$color-white: #fcfcfc;
$color-black: #000;

$color-secondary--1: #88bda0;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
