.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.8);
  z-index: 9999999999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  
  // overflow-y: auto; 

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($color-black, 0.3);
  }

  &__content {
    @include absCenter;
    width: 100%;
    height: 100vh;
    padding: 5rem 0;
    background-color: $color-white;
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    border-radius: 3px;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all 0.1s 0.2s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include respond(phone) {
      align-items: center;
      height: 50vh;
    }

    @include respond(tab-land) {
      overflow: auto;
    }

    &__heading {
      margin: 0 5rem;

      @include respond(small-phone) {
        font-size: 2rem;
        margin: 5rem 0;
      }

      @include respond(phone) {
        margin: 5rem 0;
      }
    }

    &__text {
      margin: 1rem 5rem 0 5rem;
      font-size: 2rem;
    }
  }

  //Open states
  &:target {
    opacity: 1;
    visibility: visible;
  }

  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &__close {
    &:link,
    &:visited {
      color: $color-black;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 3rem;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s;
      line-height: 1;
    }

    &:hover {
      color: $color-primary;
    }
  }
}
