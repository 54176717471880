.aboutus {
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 12rem 10rem 5rem 10rem;

    @include respond(phone) {
      align-items: center;
      height: 50vh;
    }

    @include respond(tab-land) {
      overflow: auto;
    }

    &__heading {
      // margin: 0 5rem;

      @include respond(small-phone) {
        font-size: 2rem;
        margin: 5rem 0;
      }

      @include respond(phone) {
        margin: 5rem 0;
      }
    }

    &__teambutton {
      width: 100%;
    }

    &__image {
      &__item {
        margin: 0 auto 0 auto;
        display: block;
        width: 35%;
        height: auto;
      }

      @include respond(tab-port) {
        // margin-top: 0;
        order: 1;
      }
    }

    &__text {
      margin: 1rem 0 0 0;
      font-size: 2rem;
    }
    &__cta {
      margin: 2rem 0 0 0;
      @include respond(small-phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__btn {
        margin: 0 2rem;
  
        @include respond(small-phone) {
          margin: 2rem 0;
        }
      }
    }
    &__teambutton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }
  }
}
