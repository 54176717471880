.startups {
  // padding: 5rem 0 15rem 0;
  margin: 10rem auto 10rem auto;
  // width: 30%;

  &__header {
    margin: 0 0 5rem 0;
  }

  &__content {
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 35% auto;;
    // grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));

    &__filters {
      width: 50%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__search {
        display: flex;
        &__input {
          width: 100%;
          // height: 100%;
          padding: 1rem 16px;
          margin: 0 1rem 0 0;
          font-size: 1.5rem;
          z-index: 1;
          border: 1px solid transparent;
          box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);

          &:focus {
            outline: 2px solid $color-primary;
          }
        }

        &__submit {
          // height: 68%;
          width: 50%;
          font-size: 1.5rem;
          font-weight: 600;
          transition: 0.3s all;
          border-radius: 2px;

          &:hover {
            box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
          }
        }
      }

      &__dropdown {
        margin: 0 0 2rem 0;

        &__item {
          margin: 2rem 0 0 0;
          &:active {
            outline: $color-primary;
          }

          #react-select-2-input {
            color: white;
            z-index: 2;
          }

          #react-select-3-input {
            color: white;
            z-index: 2;
          }
        }
      }

      &__pages {
        display: flex;
        &__button {
          width: 80%;
          padding: 1.5rem 0;
          font-size: 1.5rem;
          font-weight: 600;
          
          &:not(:last-child) {
            margin: 0 1rem 0 0;
          }
        }
      }
    }

    &__cards {
      width: 100%;
      display: grid;
      gap: 2rem;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      &__item {
        box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
        height: 35rem;
        width: 35rem;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        transition: 0.3s all;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
        }

        &__image {
          width: 100%;
          height: 25rem;
          border-radius: 5px;
        }

        &__name {
          font-size: 3rem;
          color: $color-primary;
          margin: 0 0 0 2rem;
        }

        &__description {
          font-size: 1.6rem;
          margin: 0 0 0 2rem;
        }
      }
    }
  }
}
