input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }

.sha-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.sha-tab {
    width: 85%;
    color: white;
    overflow: hidden;
    margin-bottom: 1.1rem;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 2rem;
      font-size: 2rem;
      background: #00BAFF;;
      border-radius: 10px;

      font-weight: bold;
      cursor: pointer;
      /* Icon */
      &:hover {
        transform: scale(1.003);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: $color-black;
      background: white;
      transition: all 0.3s;
      font-size: 2rem;
      font-weight: 500;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $color-black;
      cursor: pointer;
      &:hover {
        background: darken($color-black, 10%);
      }
    }
  }

  // :checked
input:checked {
    + .sha-tab-label {
      background: #00BAFF;;
      border-radius: 10px 10px 0px 0px;

      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .sha-tab-content {
      max-height: 100vh;
      padding: 1em;
      background: #EDEDED;
      border-radius: 0px 0px 10px 10px;
    }
  }
