body {
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2900px;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
}

.grid {
    display: grid;
    grid-template-columns: 30px auto auto auto auto 30px;
    gap: 20px;
    padding: 0, 30px;
    margin: 0 auto;
    grid-template-areas: ". content content content content .";
}

.hero-header {
    background-image: linear-gradient(63.18deg, rgba(0, 69, 172, 0.47), rgba(0, 0, 0, 0.4)), url('../../assets/shakaijin-landing.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 100%
}

.header-content {
    grid-area: content;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
}

.sha-landing-hero-content {
    margin: 12% 10% 22% 10%;
}

.sha-title {
    font-size: 6.5em;
    line-height: 1.6em;

    color: #FEFEFE;
}

.sha-sub-title {
    font-size: 3em;
    line-height: 1.6em;

    color: #CCCCCC;
}



.sha-body {
    text-align: center;
    max-width: 1600px;
    margin: 0 auto;
}


.sha-details-title {
    font-weight: bold;
    font-size: 4.8em;
    line-height: 1.5em;

    margin-top: 1.5em;

    color: #535353;
}

.sha-details-body {
    display: grid;
    grid-template-columns: 20px 43% 43% 20px;
    gap: 30px;
    padding: 0, 20px;
    margin: 3em auto;
    justify-content: center;

    grid-template-areas: ". sha_details_list sha_details_img .";
    width: 100%;
}


.sha-details-list {
    grid-area: sha_details_list;
    margin-top: 2em;
    text-align: left;
}

.sha-details-img {
    grid-area: sha_details_img;
}


.program-details-component {
    display: flex;
    margin-bottom: 5em;
}

.details-img {
    height: 6.5em;
    margin-left: 5rem;
}

.details-txt {
    font-weight: 500;
    font-size: 3em;
    line-height: 1.3em;
    
    padding-right: 1em;
    margin-left: 1em;
    
    color: #00BAFF;
}


.sha-japan-title {
    font-weight: bold;
    font-size: 4.8em;
    line-height: 1.5em;

    margin-top: 1.5em;

    color: #535353;
}

.sha-japan-body {
    margin: 3.5em 40px 10% 40px;
    font-weight: 600;
}

.why-japan-component {
    display: flex;
    background-color: #00BAFF;
    border-radius: 10px;
    padding: 3em;
    margin: 5em 4em 0 4em;
    text-align: left; 

}

.why-japan-num-img {
    width: 8em;
    height: 8em;
    margin: auto 2em;
}

.why-japan-text {
    font-size: 3.8em;
    line-height: 1.3em;
    color: #FFFFFF;

    margin-left: 0.8em;
    margin-right: 2.5em;
    padding: 0.5em;
}


// buttonssss

.sha-join-program-btn {
    font-size: 3em;
    font-weight: bold;
    background: #00BAFF;
    border-radius: 5px;

    margin-top: 1.7em;
    padding: 0.5em 1.3em 0.5em 1.3em;

    color: #ffffff;
}

.sha-know-more-btn {
    font-size: 3em;
    font-weight: bold;
    background: transparent;
    border-radius: 5px;

    margin-left: 1.4em;
    padding: 0.4em 1.3em 0.4em 1.2em;

    border: 3px solid #FFFFFF;
    box-sizing: border-box;

}



