
.select-search {
    /* width: 300px; */
    cursor: pointer;
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    /* position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box; */
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    /* position: relative;
    z-index: 1; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: baseline;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search__value::after {
    content: '';
    /* display: inline-block;
    position: absolute; */
    /* top: 10px; */
    /* right: 35px; */
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 4rem;
    width: 90%;
    /* padding: 0 16px; */
    background: #fff;
    border: 1px solid transparent;
    /* box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15); */
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.5rem;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    z-index: -1;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    margin: 3rem 0 0 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.5rem;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    overflow: auto;
    max-height: 20rem;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}


.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  border: none;
  border-radius: 3px;
  outline: none;
  background: rgb(49, 173, 122);
  color: #fff;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
}

.select {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s opacity;
}

.options {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  padding: 16px 0;
  border-radius: 10px;
  background: #ffff;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: 0.3s transform;
}

.option {
  display: block;
  width: 100%;
  padding: 16px 24px;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  text-align: left;
  cursor: pointer;
  transition: 0.3s background;
  outline: none;
}

.option.is-selected {
  background: rgb(103, 121, 225);
  color: #fff;
}

/* .enter .select {
  opacity: 0;
} */

.enter-active .select {
  opacity: 1;
}
.exit .select {
  opacity: 1;
}
.exit-active .select {
  opacity: 0;
}

.enter .options {
  transform: translate(-50%, calc(-50% + 20px));
}

.enter-active .options {
  transform: translate(-50%, -50%);
}
.exit .options {
  transform: translate(-50%, -50%);
}
.exit-active .options {
  transform: translate(-50%, calc(-50% + 20px));
}

input {
    opacity: 1 !important;
    /* z-index: 1; */
    position: relative !important;
}