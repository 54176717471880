.readytojoin {
  padding: 10rem 0;
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__heading {
    font-size: 3rem;
    color: $color-white;
  }

  &__cta {
    margin: 2rem 0 0 0;
    @include respond(small-phone) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__btn {
      margin: 0 2rem;

      @include respond(small-phone) {
        margin: 2rem 0;
      }
    }
  }
}
