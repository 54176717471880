.blogs {
  margin: 5rem 0;
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__subheading {
      color: $color-black;
      font-size: 1.6rem;
    }

    &__heading {
      color: $color-primary;
      font-size: 5rem;
      line-height: 7rem;
    }
  }

  &__content {
    margin: 5rem 0;
    display: grid;
    gap: 0.5rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    @include respond(small-phone) {
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
    &__card {
      width: 90%;
      text-align: justify;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      box-shadow: 5px 5px 10px rgba(#898989, 0.2);
      &__image {
        width: 100%;
        height: auto;
        border-radius: 20px 20px 0 0;
      }

      &__title {
        font-size: 4rem;
        color: $color-primary;
      }

      &__date {
        margin: 0 5rem 2rem 5rem;
        font-size: 2.5rem;
        color: $color-black;
      }

      &__description {
        margin: 1rem 5rem;
        font-size: 2rem;
      }
    }
  }
}
