.team {
  margin-top: 5rem;
  &__content {
    &__grid {
      &__header {
        margin: 15rem 0 5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone) {
          margin: 10rem 0;
        }

        &__subheading {
          font-size: 2rem;
          line-height: 2rem;
        }

        &__heading {
          text-align: left;
          display: block;
          font-size: 5rem;
          line-height: 6rem;

          @include respond(phone) {
            text-align: center;
          }

          span {
            font-size: 5rem;
            color: $color-primary;
          }
        }
      }

      &__content {
        margin: 0 10rem 5rem 10rem;
        display: grid;
        gap: 5rem;
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
        &__member {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow: 0rem 0.2rem 5rem rgba($color: $color-black, $alpha: 0.1);
          -webkit-filter: grayscale(90%);
          filter: grayscale(90%);
          transition: 0.3s all;
          width: 28vw;
          margin: 0.5vw;
          
          &:hover {
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
          }

          &__image {
            width: 100%;
            height: 35rem;

            @include respond(tab-port) {
              width: 100%;
              height: auto;
            }
          }

          &__name {
            font-size: 3rem;
            font-weight: 500;
            margin-top: 2rem;
          }

          &__position {
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 2rem;
          }

          &:hover {
            box-shadow: 2rem 2rem 5rem rgba($color: $color-black, $alpha: 0.2);
            transition: 0.5s all;
          }
        }

        &__link {
          text-decoration: none;
          color: $color-black;
        }

        @include respond(tab-land) {
          margin: 2rem 2rem;
        }
      }
    }

    &__popup {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba($color-black, 0.8);
      z-index: 999999;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      &:active {
        z-index: 0;
        opacity: 0;
        visibility: hidden;
      }

      @supports (-webkit-backdrop-filter: blur(10px)) or
        (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($color-black, 0.3);
      }

      &__content {
        @include absCenter;

        width: 100%;
        height: 100vh;
        padding: 5rem 0;
        background-color: $color-white;
        box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
        border-radius: 3px;
        overflow: hidden;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.25);
        transition: all 0.5s 0.2s;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &__heading {
          margin: 0 5rem;

          @include respond(small-phone) {
            font-size: 2rem;
          }
        }

        &__text {
          margin: 1rem 5rem 0 5rem;
          font-size: 2rem;
          font-weight: 500;
        }

        &__social {
          &__button {
            padding: 0 10rem;
            margin: 2rem 0 0 0;
            display: flex;
            justify-content: space-evenly;
            &__link {
              margin: 0 1rem;
              &__item {
                font-size: 3rem;
                color: $color-black;
                transition: 0.5s all;

                @include respond(phone) {
                  font-size: 5rem;
                }

                &:hover {
                  color: $color-primary;
                }
              }
            }
          }
        }
      }

      //Open states
      &:target {
        opacity: 1;
        visibility: visible;
      }

      &:target &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }

      &__close {
        &:link,
        &:visited {
          color: $color-black;
          position: absolute;
          top: 2.5rem;
          right: 2.5rem;
          font-size: 3rem;
          text-decoration: none;
          display: inline-block;
          transition: all 0.2s;
          line-height: 1;

          @include respond(phone) {
            font-size: 5rem;
          }
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}
