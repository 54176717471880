.whatweoffer {
  &__header {
    margin: 0 0 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__subheading {
      margin-top: 3rem;
      font-size: 2rem;
      line-height: 2rem;
    }

    &__heading {
      text-align: left;
      display: block;
      font-size: 5rem;
      line-height: 6rem;

      span {
        font-size: 5rem;
        color: $color-primary;
      }
    }

    &__info {
      display: block;
      margin: 0 0 2rem 0;
      font-size: 1.6rem;
      color: $color-black;

      @include respond(phone) {
        font-size: 2rem;
        margin: 0 0 0 2rem;
      }
    }
  }

  &__grid {
    margin: 0 auto;
    width: 80%;
    display: grid;
    gap: 8rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    @include respond(phone) {
      gap: 2rem;
    }

    &__item {
      align-self: end;
      &__content {
        display: grid;
        gap: 0.5rem;
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        @include respond(phone) {
          margin: 5rem 0 5rem 2rem;
        }

        @include respond(small-phone) {
          gap: 2rem;
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        }

        &__image {
          &__item {
            margin: 0 auto 0 auto;
            display: block;
            width: 65%;
            height: auto;
          }
        }

        &__text {
          color: $color-primary;
          &__heading {
            text-align: left;
            display: block;
            font-size: 4rem;
            line-height: 6rem;

            @include respond(tab-land) {
              font-size: 3.5rem;
            }
          }

          &__subheading {
            margin-top: 3rem;
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 400;

            @include respond(tab-land) {
              margin: 0 2rem 0 0;
              line-height: 3rem;
            }
          }
        }
      }
    }
  }

  &__ctasection {
    margin: 5rem 0;
    margin-bottom: 0;
    padding: 5rem 0;
    background-color: $color-primary;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &__heading {
      margin-bottom: 2rem;
      font-size: 3rem;
      color: $color-white;
    }
  }
}
