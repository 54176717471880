.faq {
  margin: 13rem 0 5rem 0;
  &__header {
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__subheading {
      font-size: 2rem;
      line-height: 2rem;
    }

    &__heading {
      text-align: left;
      display: block;
      font-size: 5rem;
      line-height: 6rem;

      span {
        font-size: 5rem;
        color: $color-primary;
      }
    }

    &__info {
      display: block;
      margin: 0 0 2rem 0;
      font-size: 1.6rem;
      color: $color-black;

      @include respond(phone) {
        font-size: 2rem;
        margin: 0 0 0 2rem;
      }
    }
  }
}
