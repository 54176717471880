input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  //   border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.tab {
  width: 80%;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
  
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    font-size: 2rem;
    background: $color-black;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($color-black, 20%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $color-black;
    background: white;
    transition: all 0.3s;
    font-size: 2rem;
    font-weight: 500;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $color-black;
    cursor: pointer;
    &:hover {
      background: darken($color-black, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($color-black, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}
