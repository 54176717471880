.whatweare {
  margin: 5rem 0 0 0;

  &__content {
    display: grid;
    gap: 0.5rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    &__image {
      &__item {
        width: 70%;
        height: auto;
        margin: 0 auto;
        display: block;
      }
    }

    &__text {
      width: fit-content;
      background-color: $color-black;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @include respond(tab-land) {
        padding: 10rem 0;
      }

      &__heading {
        font-size: 3rem;
        color: $color-white;
        line-height: 6rem;
        margin: 0 15rem;

        @include respond(phone) {
          margin: 0;
          line-height: 5rem;
        }

        span {
          display: block;
          color: $color-primary;
        }
      }

      &__subheading {
        margin-top: 1rem;
        margin: 0 15rem;
        font-size: 2rem;
        font-weight: 400;
        color: $color-white;

        @include respond(phone) {
          margin: 0 2rem;
        }
      }
    }
  }
}
