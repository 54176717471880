.reportshowcase {
  margin: 12rem 0;

  &__header {
    margin: 0 0 5rem 0;
    &__slidshow {
      margin: 0 auto;
      width: 50%;

      @include respond(tab-land) {
        width: 80%;
      }

      @include respond(phone) {
        width: 100%;
      }

      

      &__image {
        @include respond(tab-land) {
          width: 80%;
          margin: 0 2rem;
        }
        &__main {
          margin: 0 auto;
          width: 100%;
          height: 40rem;

          
        }

        &__grid {
          display: grid;
          gap: 0.5rem;
          justify-items: center;
          align-items: center;
          grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
          &__item {
            width: 100%;
            // height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;

    &__report {
      &__link {
        font-size: 3rem;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}
