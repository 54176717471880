.notfound {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    &__label {
      margin: 3rem;
    }
    &__link {
      &:hover {
        text-decoration: none;
      }
    }
    &__button {
      display: block;
      margin: auto;
      background-color: $color-primary;
      color: $color-white;
      outline: none;
    }
  }
}
