.contactus {
  margin: 12rem auto 5rem auto;
  display: flex;
  flex-direction: column;
  width: 50%;

  &__header {
      &__heading {
        margin: 0 0 2rem 0;
      }
  }

  &__content {
    &__form {
      display: flex;
      flex-direction: column;
      &__label {
        font-size: 2.5rem;
        font-weight: 600;
      }

      &__input {
        z-index: 1;
        margin: 1rem 0 2rem 0;
        padding: 2rem;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: rgba($color: $color-primary, $alpha: 0.2);

        &:focus {
          color: $color-white;
          background-color: rgba($color: $color-primary, $alpha: 0.8);
        }
      }

      &__textarea {
        margin: 1rem 0 2rem 0;
        padding: 2rem;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: rgba($color: $color-primary, $alpha: 0.2);

        &:focus {
          color: $color-white;
          background-color: rgba($color: $color-primary, $alpha: 0.8);
        }
      }

      &__radio {
        margin: 3rem auto;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__item {
          display: none;

          

          &__label {
            padding: 0 3rem;
            height: 12rem;
            font-size: 1.6rem;
            font-weight: 600;
            background: $color-white;
            color: $color-black;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
            transition: 0.3s all;

            // &:not(:first-child) {
            //     margin: 0 0 0 2rem;
            // }

            &:last-child {
                margin: 0 0 0 5rem;
            }
          }

          &:checked + &__label {
            padding: 0 5rem;
            height: 12rem;
            font-size: 2rem;
            font-weight: 600;
            background: $color-primary;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
          }
        }
      }

      &__button {
          padding: 2rem 0;
          font-size: 3rem;
          font-weight: 600;
      }
    }
  }

  &__message {
    margin: 0 auto;
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }
}

//   .modal-tile {
//     background: $color-primary;
//     color: $color-white;
//     width: 30%;
//     height: 12rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;

//     &:active {
//       transform: scale(1.1);
//     }
//   }

// .modal-radio-label {
//   padding: 0 3rem;
//   height: 12rem;
//   font-size: 1.6rem;
//   font-weight: 600;
//   background: $color-white;
//   color: $color-black;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
//   transition: 0.3s all;
// }

// .contactus__content__form__radio__item:checked
//   + .contactus__content__form__radio__item__label {
//   padding: 0 5rem;
//   height: 12rem;
//   font-size: 2rem;
//   font-weight: 600;
//   background: $color-primary;
//   color: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
// }
