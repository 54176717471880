.form {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 2rem 10rem;
    border-radius: 5px;
    background-color: $color-white;
    border: none;
    border-bottom: 3px solid $color-primary;
    transition: all 0.3s;
    text-align: center;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-primary;
    }

    &::-webkit-input-placeholder {
      color: $color-primary;
      font-weight: 600;
      font-size: 2rem;
    }
  }

  #message {
    padding: 5rem 10rem;
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__submit__button {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    border: none;
    cursor: pointer;
    background-color: $color-white;
    color: $color-primary;
  }
}
