.ShaFooter {
    position: absolute;
    max-width: 2900px;
    width: 100%;
    margin: 0 auto;
    // height: 500px;
    color: #00BAFF;
}

.shakaijin-footer-bg {
    background-image: url('../../assets/sha-footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    max-width: 2900px;
    padding: 25em 10em 15em 10em;

}

.sha-footer-content {
    margin: 0 auto;
    max-width: 1500px;
}

.sha-footer-left {
    margin-top: 5em;
    margin-left: 12em;
    padding-right: 10em;
}

.sha-footer-txt-1 {
    font-size: 4.5em;
    color: #FFFFFF;
}


#btn-shakaijin-reg-now {
    font-size: 3.1em;
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0.6em 2.5em;
    color: #00BAFF;
}


.sha-footer-txt-2 {
    font-size: 4.5em;
    color: #ffffff;
    align-items: left;
}

#btn-shakaijin-faqs {
    font-size: 3.1em;
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 1em;
    padding: 0.6em 2.5em;
    color: #00BAFF;
}

#btn-shakaijin-enquire-submit {
    font-size: 3.1em;
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0.6em 3.7em;
    color: #00BAFF;
}
