.footer {
  &__content {
    padding: 10rem 0;
    background-color: $color-black;
    display: grid;
    gap: 3rem;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    @include respond(small-phone) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }

    &__about {
      &__image {
        &__item {
          margin: 0 auto 0 auto;
          display: block;
          width: 30rem;
          height: auto;
        }
      }

      &__social {
        padding: 0 10rem;
        margin: 2rem 0 0 0;
        display: flex;
        justify-content: space-evenly;
        &__link {
          margin: 0 1rem;
          &__item {
            font-size: 3rem;
            color: $color-white;
            transition: 0.5s all;

            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    &__links {
      &__heading {
        font-size: 3rem;
        color: $color-primary;
      }

      &__list {
        display: block;
        text-align: left;
        &__item {
          text-decoration: none;
          color: $color-white;
          font-size: 2rem;
          transition: 0.5s all;

          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    &__more {
      &__cta {
        &__heading {
          color: $color-white;
          font-size: 2rem;
        }

        &__link {
          text-decoration: none;
        }

        &__button {
          width: 100%;
          border-radius: 5px;
          margin: 1rem 0 0 0;
          padding: 2rem 0;
          font-size: 2rem;
          font-weight: 600;
        }
      }

      &__newsletter {
        margin: 3rem 0 0 0;
        &__heading {
          color: $color-white;
          font-size: 2rem;
        }

        &__form {
          margin: 1rem 0 0 0;
          display: flex;
          flex-direction: column;
          &__input {
            z-index: 1;
            padding: 2rem;
            font-size: 2rem;
            border: none;
            outline: none;
            border-radius: 5px;
          }

          &__submit {
            border-radius: 5px;
            margin: 1rem 0 0 0;
            padding: 2rem 0;
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.sha-footer {
  position: absolute;
  width: 100%;
  // height: 747px;
  height: 100%;
  max-width:1600px;
  max-height:900px;
  left: 0px;
  top: 3350px;
  

  color: #ffffff;
}

.sha-footer-hero-image {
  background-image: url('../../assets/sha-footer-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  width: 100%;
  max-width:1600px;
  height: 100%;
}


.footer-txt-1 {
  position: absolute;
  width: 344px;
  height: 72px;
  left: 116px;
  top: 350px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
}

.footer-txt-2 {

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;

  position: absolute;
  width: 375px;
  height: 72px;
  left: 820px;
  top: 260px;

}

.sha-enquire-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;

  position: absolute;
  width: 450px;
  height: 84px;
  left: 844px;
  top: 200px;

  background: #FFFFFF;

}
